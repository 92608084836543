/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import QSModal from '../modal';
import { QSButton, QSIconButton, QSTypography } from '../../atoms';
import { Close } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import QSTextInput from '../text-input';
import { BORDER_COLOR } from '../../../typings';
import { rechargeWallet } from '../../../requests/wallet-balance';
import useNotifications from '../../../hooks/use-notifications';
import Loader from '../loader';
import { useAtom } from 'jotai';
import { rechargeWalletAtom } from '../../../../modules/home/v1/states/recharge-wallet';
import { openPopup } from '../../../utils/popup';
import InformationIcon from '../../../icons/Information';
import { Icon } from '@mui/material';
import { generateRedirectUrl } from '../../../utils/redirect-url';
import config from '../../../config/request';

const RechargeModal = () => {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const [rechargeModal, setRechargeModal] = useAtom(rechargeWalletAtom);

  const onClose = useCallback(() => {
    setRechargeModal({
      isModalActive: false,
    });
  }, [setRechargeModal]);

  const totalAmount = useMemo(() => {
    if (!amount || Number(amount) === 0) {
      return 0;
    }
    return (Number(amount) * 1.18).toFixed(2);
  }, [amount]);

  const { addNotification } = useNotifications();

  const onClickRecharge = useCallback(async () => {
    try {
      setLoading(true);
      const { paymentLink } = await rechargeWallet({
        amount: Number(totalAmount),
        redirectUrl: window.location.href,
      });
      openPopup(
        generateRedirectUrl({
          baseUrl: `${config.dtCallbackUrl}/redirect`,
          redirectUrl: paymentLink,
          encodeBase64: true,
        })
      );

      onClose();
    } catch (error) {
      addNotification({
        message:
          (error as Error).message ??
          'An error occurred while recharging wallet',
        type: 'error',
      });
    } finally {
      setLoading(false);
      setAmount('');
    }
  }, [addNotification, onClose, totalAmount]);

  if (!rechargeModal.isModalActive) {
    return null;
  }

  return (
    <QSModal open={rechargeModal.isModalActive} onClose={onClose}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 50%;
          border-radius: 4px;
          overflow: hidden;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          outline: none;
          &:focus-within {
            outline: none;
          }
          width: 350px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
            border-bottom: 1px solid ${BORDER_COLOR};
          `}
        >
          <QSTypography
            css={css`
              font-weight: bold;
            `}
          >
            Wallet Recharge
          </QSTypography>
          <QSIconButton css={css``} sx={{}} onClick={onClose}>
            <Close />
          </QSIconButton>
        </div>
        <div
          css={css`
            flex: 1;
            overflow: auto;
            padding: 16px;
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0;
            }
          `}
        >
          <QSTextInput
            headerTitle={'Amount'}
            value={amount}
            headerProps={{
              sx: {
                color: '#2d2d2d',
                fontSize: '16px',
                fontWeight: 'bold',
              },
            }}
            onChange={(event) => {
              // disable negative numbers
              if (Number(event.target.value) < 0) {
                return;
              }
              setAmount(() => event.target.value);
            }}
            textInputProps={{
              type: 'number',
            }}
            placeholder={'Enter Amount'}
          />

          {amount ? (
            <div
              css={css`
                margin-top: 16px;
              `}
            >
              <QSTypography
                css={css`
                  font-weight: bold;
                `}
              >
                {'GST (18%)'}
              </QSTypography>
              <QSTypography>
                Rs.
                {(Number(amount) * 0.18).toFixed(2) ?? '0'}
              </QSTypography>
              <QSTypography
                variant={'caption'}
                css={css`
                  display: flex;
                  align-items: center;
                  color: #555;
                  margin-top: 10px;
                  column-gap: 8px;
                `}
              >
                <Icon
                  css={css`
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 1px solid #555;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <InformationIcon size={9} color={'#555'} />
                </Icon>
                Wallet recharge is non-refundable
              </QSTypography>
            </div>
          ) : null}

          <div
            css={css`
              margin-top: 16px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <QSTypography
              css={css`
                font-weight: bold;
              `}
            >
              Total
            </QSTypography>
            <QSTypography
              css={css`
                font-weight: bold;
              `}
            >
              Rs. {totalAmount ?? '0'}
            </QSTypography>
          </div>

          <div
            css={css`
              margin-top: 16px;
            `}
          >
            {!loading ? (
              <QSButton
                css={css`
                  width: 100%;
                `}
                onClick={onClickRecharge}
                disabled={!totalAmount}
              >
                Recharge Now
              </QSButton>
            ) : (
              <Loader secondary="" size={16} />
            )}
          </div>
        </div>
      </div>
    </QSModal>
  );
};

export default RechargeModal;

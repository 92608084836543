import { useCallback, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { getTimeDiffJson } from '../utils/get-time-from-timestamp';

const useSocket = (
  getSocket: () => Socket,
  onReconnect?: (lastUpdatedTimestamp: number) => void
) => {
  const [socket, setSocket] = useState(getSocket());

  const isConnectedRef = useRef(false);
  const lastUpdatedTimestampRef = useRef(new Date().getTime());
  const onReconnectRef = useRef(onReconnect);

  onReconnectRef.current = onReconnect;

  const addListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.on(eventName, listener);
    },
    [socket]
  );

  const removeListener = useCallback(
    (eventName: string, listener: () => void) => {
      socket.off(eventName, listener);
    },
    [socket]
  );

  useEffect(() => {
    setSocket(getSocket());
  }, [getSocket, setSocket]);

  useEffect(() => {
    const onConnect = () => {
      isConnectedRef.current = true;
    };
    const onDisconnect = () => {
      if (navigator.onLine) {
        socket.connect();
        return;
      }
      isConnectedRef.current = false;
      lastUpdatedTimestampRef.current = new Date().getTime();
      socket.disconnect();
    };

    socket.connect();
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    const reConnect = () => {
      if (isConnectedRef.current) {
        return;
      }
      const lastUpdatedTime = lastUpdatedTimestampRef.current;
      if (lastUpdatedTime) {
        const { elapsedHours } = getTimeDiffJson(lastUpdatedTime);

        if (elapsedHours > 6) {
          window.location.reload();
          return;
        }
      }
      socket.connect();
      onReconnectRef.current?.(lastUpdatedTimestampRef.current);
    };

    window.addEventListener('focus', reConnect);
    window.addEventListener('online', reConnect);

    return () => {
      window.removeEventListener('focus', reConnect);
      window.removeEventListener('online', reConnect);
    };
  }, [socket]);

  return { socket, addListener, removeListener };
};

export default useSocket;

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getMessaging,
  getToken,
  deleteToken,
  isSupported,
} from 'firebase/messaging';
import config from '../../shared/config/firebase';
import appConfig from '../../shared/config/app';

export const firebaseApp = initializeApp(config);
export const firebaseAuth = getAuth(firebaseApp);

export const messaging = getMessaging();

export const getCurrentUserId = () => firebaseAuth.currentUser?.uid;

export const getMessagingToken = (registration?: ServiceWorkerRegistration) => {
  return getToken(messaging, {
    vapidKey: appConfig.firebaseVapidKey,
    serviceWorkerRegistration: registration,
  });
};

export const isFirebaseMessagingSupported = async () => {
  return isSupported();
};

export const deleteMessagingToken = () => {
  return deleteToken(messaging);
};

import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  AppProtectedRoutes,
  AppUnprotectedRoutes,
} from '../routes/auth-routes';
import Loader from '../../../shared/components/molecules/loader';
import { LOADING, MUI_LICENCE_KEY } from '../../../shared/constants';
import {
  deleteMessagingToken,
  firebaseAuth,
  messaging,
} from '../../../infra/firebase/init';
import {
  onAuthStateChanged,
  User as FirebaseUser,
  NextFn,
} from 'firebase/auth';
import { Provider, useAtom, useSetAtom } from 'jotai';
import {
  isDismissibleStateAtom,
  LoginStep,
  nextScreenStateAtom,
  requireDemoAtom,
  showLogoutModalAtom,
} from '../../../shared/states/login';
import getRequireDemo from '../../../shared/requests/request-demo';
import { firebaseSignInWithCustomToken } from '../../../infra/auth/firebase-auth';
import { registerAnalytics } from '../../../infra/analytics/init';
import { NotificationsProvider } from '../../../shared/components/molecules/notifications-provider';
import { useAuth } from '../../../shared/hooks/use-auth';
import { useSetProfile } from '../../../shared/hooks/use-profile';
import {
  useOrganizations,
  useSelectedOrganisationAtom,
} from '../../../shared/hooks/use-organizations';
import getRefreshNextState from '../../../shared/modules/demo-account/v1/requests/refresh-next-state';
import { UserAction } from '../../login/v1/typings';
import { NextScreenState } from '../../../shared/typings/screens';
import { getUserDialogContent } from '../../../shared/modules/demo-account/v1/requests/get-user-dialog-content';
import { useSetOpenTestBroadcastModal } from '../../../shared/hooks/use-open-test-broadcast-modal';
import { ROUTES } from '../../../shared/constants/routes';
import { useSubscribedPlan } from '../../renew-plan/v1/hooks/use-subscribed-plan';
import { logoutUseCase } from '../../login/v1/use-cases/logout-use-case';
import { getOrganizations } from '../../../shared/requests/organizations';
import chooseOrganisation from '../../login/v1/requests/choose-organisation';
import {
  useSetFileType,
  fetchFileTypes,
} from '../../../shared/hooks/use-file-types';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { useRegisterDevice } from '../../../shared/hooks/use-register-device';
import { useConversationPermissions } from '../../../shared/hooks/use-permissions';
import { ChatRowData } from '../../home/v1/components/chat-list-section/chat-row/typings';
import { FCMNotificationType } from '../../../shared/typings';
import { useShopifySignupDetailsAtom } from '../../shopify/v1/states';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import useScrollbar from '../../../shared/hooks/use-scrollbar';
import useConversationNavigate, {
  useCustomNavigate,
} from '../../../shared/hooks/use-conversation-navigate';
import { SendIndividualMessage } from '../typings';
import useSingleWaba from '../../../shared/hooks/use-single-waba';
import {
  FBInitConfig,
  FBLoginOptions,
  FBLoginResponse,
} from '../../embedded-signup/typings';
import { WabaChannelStatus } from '../../../shared/typings/waba';
import { trackSelectOrg } from '../../../infra/analytics/utils';
import getUserDetails from '../../home/v1/requests/get-user-details';
import { LoginStepAtom } from '../../login/v1/state';
import useNotifications from '../../../shared/hooks/use-notifications';

LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

interface CustomEventMap {
  reloadpagedata: CustomEvent<{ refreshType: string }>;
  openAddGroupMemberModal: CustomEvent<{
    groupId: string;
    groupChatId: string;
    groupChatName: string;
  }>;
  openWabaSelector: CustomEvent<Pick<SendIndividualMessage, 'data'>>;
  refreshIntegrationMember: CustomEvent<string>;
}

declare global {
  interface HTMLElement {
    mozRequestFullScreen: (options?: FullscreenOptions) => void;
    webkitRequestFullScreen: (options?: FullscreenOptions) => void;
  }

  interface Document {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    mozFullScreenEnabled: boolean;
    mozFullScreenElement: boolean;
    webkitFullscreenElement: boolean;
    webkitCurrentFullScreenElement: boolean;
    mozCancelFullScreen: () => Promise<void>;
    webkitCancelFullScreen: () => Promise<void>;
    webkitExitFullscreen: () => Promise<void>;
    featurePolicy: {
      features: () => string[];
      allowsFeature: (feature: string) => boolean;
      allowedFeatures: () => string[];
      getAllowlistForFeature: (feature: string) => string[];
    };
  }

  interface Window {
    fbq?: (
      action: string,
      eventName: string,
      params: Record<string, string>
    ) => void;
    FB?: {
      init?: (config: FBInitConfig) => void;
      login?: (
        callback: (response: FBLoginResponse) => void,
        options: FBLoginOptions
      ) => void;
    };
    fbAsyncInit?: () => void;
  }
}

const REDIRECT_TO_HOME_FROM: string[] = [
  ROUTES.BILLING_DETAILS,
  ROUTES.COMPANY_DETAILS,
  ROUTES.EMAIL_VERIFICATION,
  ROUTES.NEXT_STEPS,
  ROUTES.RENEW_ACCOUNT,
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
];

const App = memo(() => {
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const { authState, setAuthState } = useAuth();
  const [requireDemo, setRequireDemo] = useAtom(requireDemoAtom);
  const setShowLogoutModal = useSetAtom(showLogoutModalAtom);
  const setNextScreenState = useSetAtom(nextScreenStateAtom);
  const setIsDismissibleState = useSetAtom(isDismissibleStateAtom);
  const [loadingNextSteps, setLoadingNextSteps] = useState(false);
  const { fetchOrganizations } = useOrganizations();
  const [cacheKey, setCacheKey] = useState('');
  const setUserProfile = useSetProfile();
  const [selectedOrg, setSelectedOrganization] = useSelectedOrganisationAtom();
  const setSendTestBroadcast = useSetOpenTestBroadcastModal();
  const { verifySubscribedPlan, loading } = useSubscribedPlan(false);
  const loadingNextStepsRef = useRef(loadingNextSteps);
  const verifySubscribedPlanRef = useRef(verifySubscribedPlan);
  const setFileTypes = useSetFileType();
  const refreshNextScreenStep = useRef(false);
  const setShopifySignupDetails = useShopifySignupDetailsAtom()[1];
  const { navigateToConversation } = useConversationNavigate();
  const { wabaIntegrationsFetcher } = useSingleWaba(WabaChannelStatus.ACTIVE);
  const wabaIntegrationsFetcherRef = useRef(wabaIntegrationsFetcher);
  const setLoginStep = useSetAtom(LoginStepAtom);
  const location = useLocation();
  const { addNotification } = useNotifications();
  const match = matchPath(
    '/embed/conversations/chat/:integrationWabaNumber/:phone/details',
    location.pathname
  );

  useEffect(() => {
    setShopifySignupDetails({
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      orgName: searchParams.get('orgName') || '',
      designation: searchParams.get('designation') || '',
      websiteUrl: searchParams.get('websiteUrl') || '',
      email: searchParams.get('email') || '',
      shopId: searchParams.get('shopId') || '',
    });
  }, []);

  loadingNextStepsRef.current = loadingNextSteps;
  verifySubscribedPlanRef.current = verifySubscribedPlan;

  const {
    isAuthReady,
    isCheckingAuth,
    isOrgSelected,
    isIntegrationEnabled,
    isPermissionsReady,
    makePayment,
  } = authState;

  useRegisterDevice({
    isAuthReady,
    isOrgSelected,
  });

  useEffect(() => {
    if (!selectedOrg?.isDemo) return;

    const handleDemoDialogContent = async () => {
      const demoDialogContent = await getUserDialogContent();

      demoDialogContent?.allowedActions.some((actionItem) => {
        if (
          actionItem.action === UserAction.SEND_AND_RECEIVE_MESSAGES &&
          !actionItem.checked
        ) {
          setSendTestBroadcast({
            open: true,
          });
          return true;
        }

        return false;
      });
    };

    handleDemoDialogContent();
  }, [selectedOrg?.isDemo]);

  const handleNextStepScreen = useCallback(async () => {
    if (refreshNextScreenStep.current || !isOrgSelected) return;
    setLoadingNextSteps(true);

    const { nextScreenState, isDismissible } = await getRefreshNextState();

    setNextScreenState(nextScreenState);
    setIsDismissibleState(isDismissible);

    refreshNextScreenStep.current = true;

    if (nextScreenState === NextScreenState.BILLING_DETAILS) {
      navigate({ pathname: ROUTES.BILLING_DETAILS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.COMPANY_DETAILS) {
      navigate({ pathname: ROUTES.COMPANY_DETAILS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.EMAIL_VERIFICATION) {
      navigate({ pathname: ROUTES.EMAIL_VERIFICATION });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.NEW_INTEGRATION) {
      navigate({ pathname: ROUTES.NEXT_STEPS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.MAIN_SCREEN) {
      const canMakePayment = await verifySubscribedPlanRef.current();
      if (makePayment || canMakePayment) {
        navigate({ pathname: ROUTES.RENEW_PLAN });
        setLoadingNextSteps(false);
        return;
      }

      if (REDIRECT_TO_HOME_FROM.includes(window.location.pathname)) {
        navigate(
          { pathname: ROUTES.DASHBOARD.replace('/*', '/') },
          { replace: true }
        );
        setLoadingNextSteps(false);
        return;
      }

      setLoadingNextSteps(false);
      return;
    }

    setLoadingNextSteps(false);
  }, [
    isOrgSelected,
    setNextScreenState,
    setIsDismissibleState,
    makePayment,
    navigate,
    verifySubscribedPlanRef,
  ]);

  const choosingOrgWithQueryToken = useRef(false);

  const { GlobalScrollbar } = useScrollbar();

  const checkTokenAndChooseOrg = useCallback(
    async (user: FirebaseUser | null) => {
      const queryParams = new URLSearchParams(window.location.search);
      const idToken = queryParams.get('token') ?? '';
      const version = queryParams.get('version') ?? '1';
      // got idToken in params from website
      if (!idToken || choosingOrgWithQueryToken.current || version === '1') {
        return;
      }

      choosingOrgWithQueryToken.current = true;

      try {
        // When coming from | website -> logout(keep on same route)
        if (user) {
          await logoutUseCase();
        }

        const authOptions = {
          headers: {
            Authorization: idToken,
          },
        };

        const orgs = await getOrganizations({
          options: {
            ...authOptions,
          },
          authRequired: false,
        });

        if (orgs.length === 1) {
          selectedOrgRef.current = orgs[0];
        } else if (orgs.length > 1) {
          const orgsWithOwner = orgs.filter((org) => org.isOwner);

          if (orgsWithOwner.length === 1) {
            selectedOrgRef.current = orgsWithOwner[0];
          }

          if (orgsWithOwner.length > 1) {
            selectedOrgRef.current = null;
          }
        }

        if (selectedOrgRef.current) {
          const { user } = await chooseOrganisation(
            selectedOrgRef.current.orgId,
            {
              options: { ...authOptions },
              authRequired: false,
            }
          );

          await firebaseSignInWithCustomToken(user.token);

          setSelectedOrganization(() => selectedOrgRef.current);
          const data = await registerAnalytics(
            selectedOrgRef.current.orgId,
            orgs
          );

          setUserProfile(() => data?.profile);

          setAuthState({
            isAuthReady: true,
            isCheckingAuth: false,
            isOrgSelected: true,
            isIntegrationEnabled: true,
            isPermissionsReady: true,
            makePayment: false,
          });

          choosingOrgWithQueryToken.current = false;

          return;
        }
      } catch (error) {
        choosingOrgWithQueryToken.current = false;
        navigate({ pathname: ROUTES.LOGIN.replace('/*', '') });
        return;
      }
    },
    [setAuthState, setSelectedOrganization, setUserProfile]
  );

  const selectedOrgRef = useRef(selectedOrg);

  const handleAuthState = useCallback(
    async (user: FirebaseUser | null) => {
      const orgIdParam = new URLSearchParams(window.location.search).get(
        'orgId'
      );
      const organizationsData = await fetchOrganizations();
      const selectedOrgDetails = organizationsData.find(
        (org) => org.orgId === orgIdParam
      );
      const integrationWabaNumber = match?.params?.integrationWabaNumber;

      if (
        selectedOrgDetails &&
        typeof orgIdParam === 'string' &&
        orgIdParam.length > 0
      ) {
        const { user } = await chooseOrganisation(orgIdParam);
        trackSelectOrg();
        await firebaseSignInWithCustomToken(user.token);
        const profile = await getUserDetails(true);
        setUserProfile(profile);
        setSelectedOrganization(selectedOrg);
        setAuthState({
          isAuthReady: true,
          isCheckingAuth: false,
          isOrgSelected: !!selectedOrg,
          isIntegrationEnabled: true,
          isPermissionsReady: true,
          makePayment: false,
        });
        setLoginStep(LoginStep.PHONE);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('orgId');
        const updatedPathname = location.pathname.replace(
          integrationWabaNumber as string,
          String(selectedOrgDetails.wabaPhoneNumber)
        );
        window.location.replace(
          `${updatedPathname}${
            String(searchParams).length > 0 ? `?${searchParams}` : ''
          }`
        );
        return;
      } else if (
        !selectedOrgDetails &&
        typeof orgIdParam === 'string' &&
        orgIdParam.length > 0
      ) {
        addNotification({
          message: 'Selected organization absent!',
          type: 'error',
        });
      }

      await checkTokenAndChooseOrg(user);
      if (selectedOrgRef.current || choosingOrgWithQueryToken.current) return;

      const { claims } =
        ((await user?.getIdTokenResult()) as {
          claims: { orgId?: string; user_id?: string; phone?: string };
        }) ?? {};

      if (!claims) {
        setCacheKey(Date.now().toString());
        setAuthState({
          isAuthReady: false,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });

        try {
          await deleteMessagingToken();
        } catch (error) {
          // Do nothing
        }

        return;
      }

      const { orgId } = claims;

      if (!orgId) {
        setCacheKey(Date.now().toString());
        setAuthState({
          isAuthReady: true,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });
        return;
      }

      const [, canMakePayment] = await Promise.all([
        verifySubscribedPlanRef.current(),
        wabaIntegrationsFetcherRef.current(),
      ]);

      setCacheKey(Date.now().toString());
      const data = await registerAnalytics(orgId, organizationsData);
      const selectedOrganization = organizationsData.find(
        ({ orgId: id }) => id === orgId
      );

      setUserProfile(() => data?.profile);

      try {
        const fileTypesData = await fetchFileTypes();
        setFileTypes(fileTypesData);
      } catch (error) {
        // Do nothing
      }

      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: !!selectedOrganization,
        isIntegrationEnabled: true,
        isPermissionsReady: true,
        makePayment: !!canMakePayment,
      });

      if (selectedOrganization) {
        setSelectedOrganization(selectedOrganization);
      }
    },
    [
      checkTokenAndChooseOrg,
      setUserProfile,
      setFileTypes,
      setAuthState,
      setSelectedOrganization,
    ]
  );

  const { getConversationPermissions } = useConversationPermissions();

  const firebaseMessageHandler: NextFn<MessagePayload> = useCallback(
    async (payload) => {
      try {
        const { data = {} } = payload;

        const badge = '/icons/icon-192x192.png';
        const icon = '/icons/icon-192x192.png';
        const image = '/icons/icon-512x512.png';

        const pathname = window.location.pathname;

        const selectedChatPhoneNumber = pathname.split('/').pop();

        const {
          title = 'Doubletick',
          chatId = '',
          chatString,
          type,
          body = 'You have a new message',
        } = data;

        const isMessage = type === FCMNotificationType.MESSAGE;
        const isTemplateStatusUpdate =
          type === FCMNotificationType.TEMPLATE_STATUS_UPDATE;

        const isChatAssignedUpdate = type === FCMNotificationType.CHAT_ASSIGNED;

        let savedPhoneNo = '';
        let savedChatTypeId = '';
        let savedWaba = '';
        if (isMessage) {
          const {
            assignedUserId = '',
            phoneNumber,
            chatTypeId,
          } = (JSON.parse(chatString) as ChatRowData) ?? {};

          const isAccessible = await getConversationPermissions(
            chatId,
            assignedUserId
          );

          if (!isAccessible || selectedChatPhoneNumber === phoneNumber) {
            return;
          }

          savedPhoneNo = phoneNumber;
          savedChatTypeId = chatTypeId;
        }

        if (isChatAssignedUpdate) {
          const { phoneNumber, integrationWabaNumber } =
            (JSON.parse(chatString) as ChatRowData) ?? {};

          if (selectedChatPhoneNumber === phoneNumber) {
            return;
          }

          savedPhoneNo = phoneNumber;
          savedWaba = integrationWabaNumber;
        }

        const options = {
          badge,
          body: body,
          icon,
          image,
          data,
          silent: false,
          requireInteraction: false,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
        };

        const notification = new Notification(title, options);

        notification.onclick = (event: Event) => {
          notification.close();
          window.focus();

          if (isMessage || isChatAssignedUpdate) {
            navigateToConversation({
              phoneNumber: savedPhoneNo,
              chatTypeId: savedChatTypeId,
              waba: savedWaba,
            });
          }

          if (isTemplateStatusUpdate) {
            navigate({ pathname: ROUTES.TEMPLATES });
          }
        };

        notification.onclose = () => {
          notification.close();
        };
      } catch (error) {
        // Handle error
      }
    },
    [getConversationPermissions, navigate, navigateToConversation]
  );

  useEffect(() => {
    if (!isAuthReady || !isOrgSelected) return;

    const unsubscribe = onMessage(messaging, firebaseMessageHandler);

    return unsubscribe;
  }, [firebaseMessageHandler, isAuthReady, isOrgSelected]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, handleAuthState);

    return unsubscribe;
  }, [handleAuthState]);

  const checkAuth = useCallback(async () => {
    try {
      setRequireDemo((demo) => ({ ...demo, loading: true }));
      const requireDemoData = await getRequireDemo(true);

      setRequireDemo((demo) => ({
        ...demo,
        data: requireDemoData,
        loading: false,
      }));

      if (!requireDemoData.requireDemo) {
        const { token, firstTimeSetup, chooseOrganizations } = requireDemoData;

        if (!token) {
          setAuthState({
            isAuthReady: false,
            isCheckingAuth: false,
            isOrgSelected: false,
            isIntegrationEnabled: false,
            isPermissionsReady: false,
            makePayment: false,
          });
        }

        await firebaseSignInWithCustomToken(token);

        if (!firstTimeSetup && !chooseOrganizations) {
          setAuthState({
            isAuthReady: true,
            isCheckingAuth: false,
            isOrgSelected: true,
            isIntegrationEnabled: false,
            isPermissionsReady: false,
            makePayment: false,
          });
        }

        setRequireDemo((demo) => ({
          ...demo,
          data: requireDemoData,
          loading: false,
        }));
        return;
      }
    } catch (error) {
      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: false,
        isIntegrationEnabled: false,
        isPermissionsReady: false,
        makePayment: false,
      });

      setRequireDemo((demo) => ({
        ...demo,
        loading: false,
        error: error as Error,
      }));
    }
  }, [setAuthState, setRequireDemo]);

  useEffect(() => {
    if (!authState.isAuthReady) {
      return;
    }

    setShowLogoutModal(false);
    handleNextStepScreen().then(() => {});
  }, [authState.isAuthReady, handleNextStepScreen]);

  useEffect(() => {
    if (authState.isAuthReady && !authState.isOrgSelected) {
      // checkAuth();
    } else {
      setRequireDemo((demo) => ({
        ...demo,
        loading: false,
      }));
    }
  }, [
    authState.isAuthReady,
    authState.isOrgSelected,
    checkAuth,
    setShowLogoutModal,
    setRequireDemo,
  ]);

  if (
    isCheckingAuth ||
    requireDemo.loading ||
    loadingNextStepsRef.current ||
    loading ||
    choosingOrgWithQueryToken.current
  ) {
    return <Loader size={32} secondary={LOADING} />;
  }

  // if (isAuthReady && isOrgSelected && (makePayment || isRenewal)) {
  //   return <AppPaymentRoutes />;
  // }

  if (!isAuthReady || !isOrgSelected || !isIntegrationEnabled) {
    return (
      <>
        <GlobalScrollbar />
        <AppUnprotectedRoutes />
      </>
    );
  }

  if (
    isAuthReady &&
    isOrgSelected &&
    isPermissionsReady &&
    isIntegrationEnabled
  ) {
    return (
      <Provider key={cacheKey}>
        <GlobalScrollbar />
        <NotificationsProvider maxNotifications={5} />
        <AppProtectedRoutes />
      </Provider>
    );
  }

  return <Loader size={32} secondary={LOADING} />;
});

export default App;

import { NextScreenState } from '../../../shared/typings/screens';
import { WabaIntegration } from '../../../shared/typings/waba';
import { Organization } from '../../login/v1/typings';

export interface EmbedAutoLoginRequest {
  userId: string;
  sessionId: string;
}

export interface EmbedAutoLoginResponse {
  organization: Organization;
  integrations: WabaIntegration[];
  token: string;
  idToken: string;
  user: {
    countryCode: string;
    isOnWhatsapp: boolean;
    phone: string;
    isNewUser?: boolean;
  };
  nextScreenState: NextScreenState;
}

export enum EmbedSource {
  LEADSQUARED = 'leadsquared',
  SALESFORCE = 'salesforce',
  ZOHO_CRM = 'zoho-crm',
}

export interface ZohoChatDetailsRequest {
  moduleApiName: string;
  zohoCustomerId: string;
}

export interface ZohoChatDetailsResponse {
  phoneNumber: string;
  wabaNumber: string;
}

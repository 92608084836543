import {
  deleteMessagingToken,
  firebaseAuth,
} from '../../../../infra/firebase/init';
import { trackLogout } from '../../../../infra/analytics/utils';

export const logoutUseCase = async () => {
  try {
    await deleteMessagingToken();
  } catch (error) {
    // Do nothing
  }
  await firebaseAuth.signOut();
  trackLogout();
};

import { useEffect, useRef } from 'react';
import { getMessagingToken } from '../../infra/firebase/init';
import useNotifications from './use-notifications';
import { sendSetupToken } from '../requests/send-setup-token';

export const useRegisterDevice = ({
  isAuthReady,
  isOrgSelected,
}: {
  isAuthReady: boolean;
  isOrgSelected: boolean;
}) => {
  const { addNotification } = useNotifications();
  const listen = useRef(true);

  useEffect(() => {
    if (!listen.current || !isAuthReady || !isOrgSelected) return;

    const askPermissionAndRegister = async () => {
      document.removeEventListener('click', askPermissionAndRegister);
      listen.current = false;

      Notification.requestPermission()
        .then(async (permission) => {
          if (permission === 'granted') {
            const token = await getMessagingToken();
            if (!token) throw new Error('Failed to get FCM token');
            await sendSetupToken(token);
          }
        })
        .catch(() => {
          // Handle error
        });
    };

    document.addEventListener('click', askPermissionAndRegister);

    return () => {
      document.removeEventListener('click', askPermissionAndRegister);
    };
  }, [isAuthReady, isOrgSelected, addNotification]);
};

import { FC, SVGProps } from 'react';

const InformationIcon: FC<
  SVGProps<SVGSVGElement> & { size?: number }
> = ({ color, size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color}
      strokeWidth="0"
      {...props}
    >
      <path
        d="M 12 2 C 10.343 2 9 3.343 9 5 C 9 6.657 10.343 8 12 8 C 13.657 8 15 6.657 15 5 C 15 3.343 13.657 2 12 2 z M 8 10 L 8 12 L 10 12 L 10 20 L 8 20 L 8 22 L 16 22 L 16 20 L 14 20 L 14 11 C 14 10.448 13.552 10 13 10 L 11 10 L 8 10 z"
        fill={color}
      />
    </svg>
  );
};

export default InformationIcon;
